@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(243, 245, 245, 255);
}

* {
    font-family: "Open Sans";
    border-radius: 0px;
    -ms-overflow-style: none;  /* Edge */
    scrollbar-width: none; /* Firefox */
    box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --gray-mail: rgb(230,230,230);
    --gray-mail-2: rgb(180,180,180);
    --gray-one: rgb(220,220,220);
    --gray-three: rgb(190,190,190);
    --gray-two: rgb(130,130,130);
    --accent-one: rgba(11, 115, 254, 255);
    --accent-two: rgba(1, 95, 234, 255);
    --font-size-one: 14px;
    --font-size-two: 16px;
    --distinct: white;
    --almost-black: rgb(70,70,70);
    --nav-bottom: rgb(235,235,235);
    --back: rgba(243, 245, 246, 255);
    --font-size-three: 20px;
    --transition: .3s ease;
}

.signup-container {
    width: 100vw;
    height: 100vh;
    background-color: var(--accent-one);
    display: flex;
    justify-content: center;
    align-items: center;
}

.signup-container-2 {
    display: flex;
    flex-direction: column;
    width: 450px;
    height: fit-content;
    background-color: var(--distinct);
    box-shadow: 0px 10px 50px rgba(0,0,0,.9);
    padding: 10px;
}

.input-field {
    max-width: 700px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.top-input {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    column-gap: 3px;
    font-size: var(--font-size-one);
    font-weight: 600;
    max-width: 700px;
    user-select: none;
}

.bottom-input {
    width: 100%;
    max-width: 700px;
    border: none;
    border-bottom: 2px solid var(--gray-four);
    box-sizing: border-box;
    padding: 0px;
    font-size: var(--font-size-one);
    height: 30px;
    outline: none;
    border: none;
    border-bottom: 2px solid var(--gray-three);
}

.bottom-input:focus {
    outline: none;
    border-bottom: 2px solid var(--accent-one);
}


.add-a-course-form-container {
    background-color: var(--distinct);
    max-width: 90vw; /* Maximum width of 95% of the viewport width */
    max-height: 90vh; /* Maximum height of 95% of the viewport height */
    width: 450px; /* Default width of 500px */
    z-index: 10001;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: auto; /* Allows scrolling if content overflows */
    padding: 20px;
    box-shadow: 0px 30px 40px rgba(0,0,0,.4);
    box-sizing: border-box;
    flex-direction: column;
}

.add-a-course-form-content-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.form-width {
    width: 100%;
}

.add-course-title {
    color: var(--almost-black);
    font-size: 25px;
    font-weight: 700;
}

.account-settings-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 20px;
}

.required-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: 3px;
    user-select: none;
    font-size: var(--font-size-one);
}


.banner {
    width: 100%;
    font-size: var(--font-size-one);
    background-color: var(--accent-one);
    color: var(--distinct);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    min-height: 39px;
    border: none;
    overflow: auto;
    cursor: pointer;
}

.banner.top {
    border: 1px solid var(--gray-mail);
    background-color: rgba(245, 247, 249, 255);
    border-radius: 5px;
    color: var(--almost-black);
}

.role {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.role-box {
    height: 40px;
    width: 45%;
    border: 1px solid var(--gray-three);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-one);
    font-weight: 600;
    cursor: pointer;
    box-sizing: border-box;
    color: var(--almost-black);
}

.role-box.active {
    background-color: var(--accent-one);
    border: none;
    color: var(--distinct);
}

.icon {
    position: absolute;
    right: 0px;
    bottom: -15px;
    transform: translate(0px, -50%);
}

.forgot-verify {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -14px;
}

.actions {
    color: var(--accent-one);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
}

.actions:hover {
    text-decoration: underline;
}

.gray-svg-container {
    width: 100%;
    height: 160px;
    border-radius: 5px;
    border: 1px solid var(--gray-mail);
    background-color: rgba(245, 247, 249, 255);
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
}

.loading-dots {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background-color: white;
    border-radius: 50%;
    animation: blink 1.4s infinite both;
}

.dark-dot {
    background-color: #333;  
}

.dot:nth-child(1) {
animation-delay: 0s;
}

.dot:nth-child(2) {
animation-delay: 0.2s;
}

.dot:nth-child(3) {
animation-delay: 0.4s;
}

@keyframes blink {
0%, 80%, 100% {
    opacity: 0;
}
40% {
    opacity: 1;
}
}

.nav {
    width: 100%;
    height: 60px;
    background-color: var(--accent-two);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--distinct);
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}

.right-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 50px;
    color: var(--distinct);
    font-weight: 500;
}

.nav-text {
    color: var(--distinct);
    font-size: var(--font-size-two);
    cursor: pointer;
}

.signup {
    background-color: var(--distinct);
    box-sizing: border-box;
    border: 2px solid var(--distinct);
    width: 90px;
    height: 30px;
    color: var(--accent-one);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: var(--transition);
}

.signup:hover {
    background-color: transparent;
    color: var(--distinct);
}

.login {
    background-color: transparent;
    color: var(--distinct);
    box-sizing: border-box;
    border: 2px solid var(--distinct);
    width: 90px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: var(--transition);
}

.login:hover {
    background-color: var(--distinct);
    color: var(--accent-one);
}

.body-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blue-back {
    background-color: var(--accent-one);
    width: 100%;
    height: 400px;
    position: relative;
}

.center-text {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 25px;
    width: 100%;
}

.title {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--distinct);
    font-size: var(--font-size-three);
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 10px;
    text-align: center;
}

.try-it {
    width: 210px;
    height: 35px;
    padding-left: 5px;
    background-color: var(--distinct);
    border: 2px solid var(--distinct);
    color: var(--accent-one);
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: var(--transition);
    font-size: var(--font-size-two);
    font-weight: 500;
    box-sizing: border-box;
}

.try-it:hover {
    background-color: transparent;
    color: var(--distinct);
}

.subtitle {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--distinct);
    font-size: var(--font-size-two);
    font-weight: 400;
    text-align: center;
}

.card-container {
    height: 50%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 50px;
    column-gap: 25px;
    row-gap: 25px;
    flex-wrap: wrap;
    padding-left: 15px;
    padding-right: 15px;
}

.card-container-absolute {
    width: 100%;
    max-width: 1740px;
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 50px;
    column-gap: 25px;
    row-gap: 25px;
    flex-wrap: wrap;
    padding-left: 15px;
    padding-right: 15px;
}

.center-for-cards {
    display: flex;
    justify-content: center;
    width: 100%;
}

.card {
    width: 410px;
    height: 300px;
    background-color: var(--distinct);
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0,0,0,.15);
    transition: var(--transition);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    flex-direction: column;
    color: var(--accent-one);
    box-sizing: border-box;
}

.card:hover {
    box-shadow: 0px 5px 15px rgba(0,0,0,.3);
}

.svg-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

.card-button {
    width: 200px;
    height: 30px;
    color: var(--accent-one);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-three);
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
    transition: var(--transition);
    text-decoration: underline;
}

.card-button:hover {
    color: var(--accent-two);
}

.middle-title {
    font-size: var(--font-size-three);
    font-weight: 400;
    color: var(--almost-black);
    text-align: center;
    padding: 5px;
}

.govaluate {
    width: 160px;
    height: 35px;
    padding-left: 5px;
    border: 2px solid var(--distinct);
    color: var(--distinct);
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: var(--transition);
    box-sizing: content-box;
    box-sizing: border-box;
}

.govaluate:hover {
    background-color: var(--distinct);
    color: var(--accent-one);
}   

.how-it-works {
    width: 100%;
    background-color: var(--accent-two);
    overflow: visible;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.how-title {
    padding-left: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 70px;
    color: var(--distinct);
    font-size: var(--font-size-three);
    border-bottom: 1px solid var(--accent-one);
    font-weight: 600;
    margin-bottom: 30px;
}

.solutions-header {
    padding-left: 30px;
    padding-right: 30px;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--accent-one);
}

.header {
    color: var(--distinct);
    font-size: var(--font-size-two);
    font-weight: 400;
    display: flex;
}

.feature-row {
    padding-left: 30px;
    padding-right: 30px;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed var(--accent-one);
}

.chart-works {
    width: 100%;
    background-color: var(--accent-two);
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.small-card {
    width: 240px;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0,0,0,.15);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    transition: var(--transition);
    background-color: var(--distinct);
    z-index: 4;
    padding: 30px;
    color: var(--accent-one);
}

.small-card:hover {
    box-shadow: 0px 5px 15px rgba(0,0,0,.3);  
    scale: 1.01;
}

.small-card-container {
    width: 100%;
    padding: 40px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 60px;
    row-gap: 60px;
    flex-wrap: wrap;
}

.small-card-container-phone {
    width: 100%;
    padding: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    column-gap: 60px;
    row-gap: 60px;
}

.bar {
    position: absolute;
    width: 90%;
    height: 20px;
    background-color: var(--accent-one);
    top: 32.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}

.bar-phone {
    position: absolute;
    height: 50%;
    width: 20px;
    background-color: var(--accent-one);
    top: 50px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 0;
}

.small-card-text {
    font-size: var(--font-size-two);
    color: var(--almost-black);
}

.slant-title {
    padding-left: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 70px;
    color: var(--distinct);
    font-size: var(--font-size-three);
    font-weight: 600;
    border-bottom: 1px solid var(--accent-one);
}

.small-card-text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.how-works-row {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    color: var(--distinct);
}

.how-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-direction: row;
    row-gap: 60px;
    column-gap: 20px;
    margin-top: 60px;
    flex-wrap: wrap;
}

.how-text {
    width: fit-content;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    justify-content: center;
    border-bottom: 1px solid var(--accent-one);
    text-align: center;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

.logo-text {
    color: var(--distinct);
    font-size: var(--font-size-three);
    font-weight: 600;
}

.nav-page {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: var(--accent-two);
    z-index: 1000;
    transition: 1s ease;
    color: var(--distinct);
    justify-content: center;
    align-items: center;
    row-gap: 10px;
}

.x {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1001;
    cursor: pointer;
}

.bottom-header {
    width: 100%;
    background-color: var(--accent-two);
    display: flex;
    padding: 30px;
    justify-content: space-between;
    align-items: center;
    color: var(--distinct);
    flex-direction: column;
}

.bottom-container {
    display: flex;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
    padding: 10px;
    border-bottom: 1px solid var(--accent-one);
    flex-direction: column;
}

.subtitle-bottom {
    color: var(--distinct);
    font-size: var(--font-size-two);
    font-weight: 200;
    text-align: center;
}

.priv-term {
    margin-top: -14px;
    margin-bottom: -10px;
    font-size: 12px;
    color: var(--almost-black)
}

.term-priv-span {
    text-decoration: underline;
    color: var(--accent-one);
    cursor: pointer;
}

.term-priv-span:hover {
    text-decoration: none;
    color: var(--accent-two);
}

.back-button {
    position: absolute;
    top: 15px;
    left: 15px;
    background-color: var(--accent-one);
    color: white;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
}

.back-button:hover {
    background-color: var(--accent-two);
}

.bottom-priv {
    text-decoration: underline;
    cursor: pointer;
}

.bottom-priv:hover {
    text-decoration: none;
}

.banner-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    column-gap: 10px;
    overflow-x: auto;
}

.info-container {
    width: 18px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.support-container {
    margin-top: 20px;
    width: 100%;
    height: auto;
    row-gap: 15px;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.send {
    color: white;
    background-color: var(--accent-one);
    border-radius: 5px;
    cursor: pointer;
    width: 160px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-one);
}

.support-item {
    width: 100%;
    max-width: 800px;
    padding: 10px;
    outline: none;
    border: 1px solid var(--gray-mail);
    border-radius: 5px;
    font-size: var(--font-size-one);
}

.marquee-item-wrapper {
    width: 250px;
    height: 100px;
}

.marquee-container {
    height: 100%;
    display: flex;
    align-items: center;
    column-gap: 25px;
    justify-content: center;
}

.marquee-item-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
}

.marquee-container-parent {
    border-top: 2px solid var(--gray-three);
    border-bottom: 2px solid var(--gray-three);
    background-color: var(--distinct);
}

.stats-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 40px;
    column-gap: 20px;
    row-gap: 40px;
    flex-wrap: nowrap;
}

.stats-card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
}

.bottom-of-stats-card {
    color: var(--distinct);
    font-size: var(--font-size-three);
}

.overwrite-small-card {
    color: var(--almost-black);
    font-size: 40px;
    font-weight: 700;
}

.svg-stats-card {
    color: var(--accent-one);
}